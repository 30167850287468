import React, { Fragment } from 'react'
import { ThemeProvider } from 'emotion-theming'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/styles'
import global from 'components/global'
import { ModeProvider, useMode } from 'components/mode'
import theme from './theme-material'

// Mode-enabled theme provider

const SystemProvider = props => {
  const { theme } = useMode()
  return <ThemeProvider theme={theme} {...props} />
}

// Renders a root-level component with all providers in one place

const Root = props => {
  return (
    <ModeProvider>
      <SystemProvider>
        <MaterialThemeProvider theme={theme}>
          {props.children}
        </MaterialThemeProvider>
      </SystemProvider>
    </ModeProvider>
  )
}

// Renders a page-level component with global styles

const Page = props => (
  <Fragment>
    {global}
    {props.children}
  </Fragment>
)

export const wrapRootElement = ({ element, props }) => (
  <Root {...props}>{element}</Root>
)

export const wrapPageElement = ({ element, props }) => (
  <Page {...props}>{element}</Page>
)
