// * space: 0-32 / 64-128 / 256-512 [1]
// * font sizes: 12-24 / 32-40 / 48-72 [2]

const breakpoints = ['640px', '768px', '1024px', '1280px']

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512] // [1]

const radii = [0, 2, 6, 10, 16]

const fontSizes = [12, 14, 16, 20, 24, 32, 40, 48, 56, 72] // [2]

fontSizes.body = 16
fontSizes.modes = {
  medium: { body: 20 },
  large: { body: 24 }
}

const sans =
  'system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

const mono =
  'Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace'

const fonts = {
  body: sans,
  heading: sans,
  monospace: mono,
  modes: {
    mono: { body: mono }
  }
}

const fontWeights = {
  body: 400,
  heading: 700,
  bold: 700,
  heavy: 800
}

const lineHeights = {
  base: 1.3125,
  body: 1.3125,
  heading: 1.125,
  prose: 1.5,
  modes: {
    medium: { body: 1.5 },
    large: { body: 1.7 }
  }
}

const letterSpacings = {
  body: 'normal',
  caps: '0.025em'
}

const transparent = 'transparent'
const black = '#000'
const white = '#fff'

// Color customization

const text = '#181818'
const darkGray = '#3f3f3f'
const lightText = '#767676'
const gray = '#c9c9c9'
const muted = '#eee'
const lightGray = '#fafafa'

const primary = '#e54831'
const secondary = '#ab0106'
const accent = '#e1b86d'
const background = white

const blue = '#007aff'
const darkBlue = '#049'
const lightYellow = '#ffffd0'
const lightBlue = '#e9f1fa'

const red = '#ee0000'

const colors = {
  transparent,
  black,
  white,
  text,
  darkGray,
  lightText,
  gray,
  muted,
  lightGray,
  primary,
  secondary,
  accent,
  blue,
  darkBlue,
  lightYellow,
  lightBlue,
  red,
  background,
  modes: {
    dark: {
      text: white,
      background: black
    }
  }
}

// const sizes = `/* Add */`
// const borders = `/* Add */`
// const borderWidths = `/* Add */`
// const borderStyles = `/* Add */`
// const zIndices = `/* Add */`

// Box shadows

const shadows = [
  `0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)`
]

// Animation duration

const duration = {
  fast: `150ms`,
  normal: `300ms`,
  slow: `450ms`,
  slowest: `600ms`
}

// Animation easing curves

const easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)'
const easeOut = 'cubic-bezier(0, 0, 0.25, 1)'
const easeIn = 'cubic-bezier(0.5, 0, 1, 1)'

const timingFunctions = {
  easeInOut,
  easeOut,
  easeIn
}

// Animation delay

const transitionDelays = {
  small: `60ms`,
  medium: `160ms`,
  large: `260ms`,
  xLarge: `360ms`
}

const theme = {
  breakpoints,
  space,
  radii,
  fontSizes,
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  colors,
  shadows,
  duration,
  timingFunctions,
  transitionDelays
}

export default theme
