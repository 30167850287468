// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/vercel/path0/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-qr-js": () => import("/vercel/path0/src/templates/product-qr.js" /* webpackChunkName: "component---src-templates-product-qr-js" */),
  "component---src-templates-theme-js": () => import("/vercel/path0/src/templates/theme.js" /* webpackChunkName: "component---src-templates-theme-js" */),
  "component---src-templates-collection-js": () => import("/vercel/path0/src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-blog-js": () => import("/vercel/path0/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-by-tag-js": () => import("/vercel/path0/src/templates/blog-by-tag.js" /* webpackChunkName: "component---src-templates-blog-by-tag-js" */),
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/vercel/path0/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qr-js": () => import("/vercel/path0/src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */),
  "component---src-pages-reviews-js": () => import("/vercel/path0/src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-tile-calculator-js": () => import("/vercel/path0/src/pages/tile-calculator.js" /* webpackChunkName: "component---src-pages-tile-calculator-js" */),
  "component---src-pages-visit-js": () => import("/vercel/path0/src/pages/visit.js" /* webpackChunkName: "component---src-pages-visit-js" */)
}

