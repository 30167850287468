import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  transitions: {
    create: () => 'none'
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
})

export default theme
