module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1123313603","enableOnDevMode":false},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-146057308-1"]},
    },{
      plugin: require('/vercel/path0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
