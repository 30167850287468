import React from 'react'
import { Global } from '@emotion/core'
import css from '@styled-system/css'
import 'sanitize.css' // [1]
import 'minireset.css' // [2]

if (typeof window !== 'undefined') {
  require('focus-visible') // [3]
}

// * Provides opinionated and useful browser defaults. [1]
// * Resets so semantic markup doesn't affect styling. [2]
// * Styles focus state based on the proposed :focus-visible. [3]
// * Uses classes added in `gatsby-ssr.js` to avoid flash on load. [4]

const base = ({ colors, fontSizes, fonts, lineHeights }) => {
  const colorModes = colors.modes
  const fontSizeModes = fontSizes.modes
  const fontModes = fonts.modes
  const lineHeightModes = lineHeights.modes

  const styles = {}

  Object.keys(colorModes).forEach(mode => {
    const colors = colorModes[mode]
    styles[`&.theme-color-${mode}`] = {
      color: colors.text,
      backgroundColor: colors.background
    }
  })

  Object.keys(fontSizeModes).forEach(mode => {
    const fontSizes = fontSizeModes[mode]
    styles[`&.theme-fontSize-${mode}`] = {
      fontSize: fontSizes.body
    }
  })

  Object.keys(fontModes).forEach(mode => {
    const fonts = fontModes[mode]
    styles[`&.theme-font-${mode}`] = {
      fontFamily: fonts.body
    }
  })

  Object.keys(lineHeightModes).forEach(mode => {
    const lineHeights = lineHeightModes[mode]
    styles[`&.theme-lineHeight-${mode}`] = {
      lineHeight: lineHeights.body
    }
  })

  return {
    html: {
      fontFamily: 'body'
    },
    body: {
      ...styles, // [4]
      color: 'text',
      backgroundColor: 'background',
      fontSize: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    'code, kbd, pre, samp': {
      fontFamily: 'monospace'
    }
  }
}

const focus = ({ colors }) => ({
  '.js-focus-visible :focus:not(.focus-visible)': {
    outline: 'none'
  },
  '.focus-visible.focus-visible': {
    outline: `${colors.primary} dashed 4px`,
    outlineOffset: 5
  }
})

const styles = theme =>
  css({
    ...base(theme),
    ...focus(theme)
  })(theme)

const global = <Global styles={styles} />

export default global
