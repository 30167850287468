import React from 'react'
import merge from 'lodash.merge'
import get from 'lodash.get'
import baseTheme from 'components/theme'

// Context

const ModeContext = React.createContext()

// Hook

export const useMode = () => {
  const context = React.useContext(ModeContext)

  if (!context) {
    throw new Error(`useMode must be used within a ModeProvider`)
  }

  return context
}

// Utils

const colors = ['light', 'dark']
const fontSizes = ['small', 'medium', 'large']
const fonts = ['sans', 'mono']
const lineHeights = ['small', 'medium', 'large']

const getTheme = (color, fontSize, font, lineHeight) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, color, baseTheme.colors),
    fontSizes: get(baseTheme.fontSizes.modes, fontSize, baseTheme.fontSizes),
    fonts: get(baseTheme.fonts.modes, font, baseTheme.fonts),
    lineHeights: get(
      baseTheme.lineHeights.modes,
      lineHeight,
      baseTheme.lineHeights
    )
  })

const STORAGE_PREFIX = 'theme-'
const browser = typeof window !== 'undefined'
const storage = browser && {
  get: (key, init) => window.localStorage.getItem(STORAGE_PREFIX + key) || init,
  set: (key, value) => window.localStorage.setItem(STORAGE_PREFIX + key, value)
}

// Initializes theme modes. [1]

export const ModeProvider = props => {
  const [color, setColor] = React.useState(null)
  const [fontSize, setFontSize] = React.useState(null)
  const [font, setFont] = React.useState(null)
  const [lineHeight, setLineHeight] = React.useState(null)

  const theme = getTheme(color, fontSize, font, lineHeight)

  const cycleColor = () => {
    const i = (colors.indexOf(color) + 1) % colors.length
    setColor(colors[i])
  }

  const cycleFontSize = () => {
    const i = (fontSizes.indexOf(fontSize) + 1) % fontSizes.length
    setFontSize(fontSizes[i])
  }

  const cycleFont = () => {
    const i = (fonts.indexOf(font) + 1) % fonts.length
    setFont(fonts[i])
  }

  const cycleLineHeight = () => {
    const i = (lineHeights.indexOf(lineHeight) + 1) % lineHeights.length
    setLineHeight(lineHeights[i])
  }

  React.useEffect(() => {
    const color = browser && storage.get('color')
    const fontSize = browser && storage.get('fontSize')
    const font = browser && storage.get('font')
    const lineHeight = browser && storage.get('lineHeight')
    document.body.classList.remove('theme-color-' + color)
    document.body.classList.remove('theme-fontSize-' + fontSize)
    document.body.classList.remove('theme-font-' + font)
    document.body.classList.remove('theme-lineHeight-' + lineHeight)
    setColor(color)
    setFontSize(fontSize)
    setFont(font)
    setLineHeight(lineHeight)
  }, []) // [1]

  React.useEffect(() => {
    browser && storage.set('color', color)
  }, [color])

  React.useEffect(() => {
    browser && storage.set('fontSize', fontSize)
  }, [fontSize])

  React.useEffect(() => {
    browser && storage.set('font', font)
  }, [font])

  React.useEffect(() => {
    browser && storage.set('lineHeight', lineHeight)
  }, [lineHeight])

  const value = {
    colors,
    color,
    fontSizes,
    fontSize,
    fonts,
    font,
    lineHeights,
    lineHeight,
    setColor,
    setFontSize,
    setFont,
    setLineHeight,
    cycleColor,
    cycleFontSize,
    cycleFont,
    cycleLineHeight,
    theme
  }

  return <ModeContext.Provider value={value} {...props} />
}
